import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import { FileActionService } from 'src/app/@shared/services/file-action.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { ModalComponent } from '../modal/modal.component';
import { environment } from 'src/environments/environment';
import { SignSecureService } from 'src/app/@shared/services/signsecure.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { QrService } from 'src/app/@shared/services/qr.service';
import {
  BlendMode,
  PDFDocument,
  PDFName,
  PDFString,
  breakTextIntoLines,
  cleanText,
  degrees,
  lineSplit,
  rgb,
} from 'pdf-lib';
import { PdfViewerComponent as PDFViewer } from 'ng2-pdf-viewer';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { DomSanitizer } from '@angular/platform-browser';
import fontkit from '@pdf-lib/fontkit';
import { lastValueFrom } from 'rxjs';
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.sass'],
})
export class PdfViewerComponent implements OnInit {
  @Input() pdfSrc: any = '';
  zoom: number = 1;
  totalPages: number = 0;
  pageNumber: number = 1;
  showSignatureButton: boolean = false;
  showSignSecureButton: boolean = true;
  showShareButton: boolean = false;
  isPhoneWidth: boolean = false;
  isPublished: boolean = false;
  showQRButton: boolean = false;
  showQRModal: string = 'none';
  fileData: any;

  overlayHeight: string = '100%';
  @ViewChild('viewer')
  pdfViewer!: PDFViewer;

  @ViewChild('content')
  overlay!: ElementRef;

  pdf: any;

  qrPosition: string = 'top-right';
  fileLink: string = '';

  //watermark variables
  showWatermarkModal: any = 'none';
  watermarkText: string = 'Confidential';
  watermarkPosition: string = 'center';
  watermarkOpacity = '0.5';
  watermarkTextDirection = 'diagonal';
  watermarkColor = '#C2C2C1';
  watermarkShowMarked = false;
  watermarkShowShared = false;
  disableWatermarkButton = false;

  disableQRButton: boolean = false;
  privacy: string = 'CONFIDENTIAL';

  isSignSecure: boolean = false;
  constructor(
    public dialog: MatDialog,
    private _fileActionService: FileActionService,
    private _fileService: FilesService,
    private _signsecure: SignSecureService,
    private _nav: NavigationService,
    private _auth: AuthenticationService,
    private _qr: QrService,
    private _loader: LoaderService,
    private _sign: SignSecureService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    if (screen.width >= 393 && screen.width <= 490) {
      this.isPhoneWidth = true;
    } else {
      this.isPhoneWidth = false;
    }
    this.loadFile();
    this.checkPublished(this._fileActionService?.file);

    this.isSignSecure = !!this._fileActionService?.file?.workflow;

    this.showShareButton = this._fileService.checkIfUserViewer(
      this._fileActionService?.file
    );

    this.showSignSecureButton = this._fileService.checkIfUserViewer(
      this._fileActionService?.file,
      (data: any) => data.role === 'EDITOR' || data.role === 'VIEWER'
    );

    // Temporarily disable for now
    this.showQRButton =
      this._auth.userId === this._fileActionService?.file.createdBy;

    this.disableQRButton = this._fileActionService?.file.isQRAdded;

    this.privacy = this._fileActionService?.file.privacyType ?? 'CONFIDENTIAL';

    this.disableWatermarkButton =
      !!this._fileActionService?.file?.isWatermarkAdded;

    console.log({
      disableWatermarkButton: this.disableWatermarkButton,
      isPublished: this.isPublished,
    });
  }

  async loadFile() {
    this.fileLink = this.pdfSrc;
    this.fileData = await fetch(this.pdfSrc).then(res => res.arrayBuffer());
    this.pdfSrc = this.fileData;
  }

  checkPublished(file: any) {
    file?.tags?.forEach((tag: any) => {
      if (tag.name.toLowerCase() == 'published') {
        this.isPublished = true;
      }
    });

    this.isPublished = file.isPublished;
  }

  zoomLevel(level: string) {
    switch (level) {
      case 'in':
        if (this.zoom <= 2.75) {
          this.zoom = +this.zoom + +0.25;
        }
        break;
      case 'out':
        if (this.zoom >= 0.5) {
          this.zoom = this.zoom - 0.25;
        }
        break;
      default:
        break;
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdf = pdf;
    this.totalPages = pdf.numPages;
    this.overlay.nativeElement.addEventListener(
      'scroll',
      this.onScroll.bind(this)
    );
  }

  pageNavi(navi: string) {
    switch (navi) {
      case 'up':
        if (this.pageNumber > 1) this.pageNumber = this.pageNumber - 1;
        break;
      case 'down':
        if (this.pageNumber < this.totalPages)
          this.pageNumber = this.pageNumber + 1;
        break;
      default:
        break;
    }
  }

  goBack() {
    this._fileActionService.setIsModify(false);
    this._fileActionService.setIsViewFile(false);
  }

  download(name?: string) {
    console.log({ link: this.fileLink });
    lastValueFrom(
      this._fileService.downloadDirectory(this._fileActionService?.file?.id)
    );
    if (name) {
      this._fileService.downloadFile(name, this.fileLink);
    } else {
      const file =
        this._fileActionService?.file?.actions?.download ||
        this._fileActionService?.file?.actions?.download_normal ||
        this._fileActionService?.file?.actions?.choices?.download;
      this._fileService.downloadFile(file.name, this.fileLink);
    }
  }

  async useSignSecure(name?: string) {
    if (this.isPublished) return;

    const file =
      this._fileActionService?.file?.actions?.download ||
      this._fileActionService?.file?.actions?.choices?.download ||
      this._fileActionService?.file?.actions?.download_normal;

    let arrayBuffer;
    if (this.pdfSrc instanceof ArrayBuffer) {
      arrayBuffer = this.pdfSrc;
    } else {
      arrayBuffer = await fetch(this.pdfSrc).then(res => res.arrayBuffer());
    }

    this._nav.showWorkflow();
    this._signsecure.clearWorkFlowData();

    let link = './assets/dms-documents/pdf-test.pdf';
    if (!environment.local) {
      link = `./assets/${file.path}`;
    }

    console.log({ file });
    const fileObj = this._signsecure.bytesToFile(arrayBuffer, file.name);
    this._signsecure.setId(file.id);
    this._signsecure.setFile(fileObj, link);
    this._signsecure.setFileInfo(file.name, file.description);
    this._signsecure.saveWorkflowData();
    this._signsecure.nextProgress(2);
    this.goBack();
  }

  openModal() {
    if (this.isPublished) return;

    const file =
      this._fileActionService?.file?.actions?.download ||
      this._fileActionService?.file?.actions?.download_normal ||
      this._fileActionService?.file?.actions?.choices?.download;

    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '636px',
      data: {
        action: 'share-permission',
        title: file.name,
        fileId: file.id,
        data: [...file.userGroups, ...file.users] || [],
      },
    });
    dialogRef?.afterClosed()?.subscribe(result => {
      this._fileActionService.file.groups = result.userGroups;
      this._fileActionService.file.users = result.users;
    });
  }

  async showQRScreen() {
    if (this.disableQRButton) {
      return;
    }

    if (this.showQRModal === 'none') {
      await this.addQrCode();

      const height =
        this.pdfViewer.pdfViewerContainer?.nativeElement?.scrollHeight;
      this.overlayHeight = `${height + 90}px`;
      console.log({ height: this.overlayHeight });
      this.showQRModal = 'flex';
    } else {
      this.showQRModal = 'none';
      this.pdfSrc = this.fileData;
    }
  }

  async qrPositionChanged(event: any) {
    const position = event?.value ?? 'top-right';
    await this.addQrCode(position);
  }

  async addQrCode(position: string = 'top-right') {
    const link = this._qr.encrypt(this._fileActionService?.file?.id);
    const pdfDoc = await PDFDocument.load(this.fileData);
    const qrcode = await this._qr.getQRCode(link);
    const pages = pdfDoc.getPages();
    const pagesLength = pages?.length ?? 0;
    const fontBytes = await fetch('../../../assets/fonts/DMSans.ttf').then(
      res => res.arrayBuffer()
    );
    pdfDoc.registerFontkit(fontkit);
    const docFont = await pdfDoc.embedFont(fontBytes);

    for (let page = 0; page < pagesLength; page++) {
      const image = await pages[page].doc.embedPng(qrcode);
      const size = pages[page].getSize();
      console.log({ page: pages[page] });
      const pos = this._qr.getQRCodeConfig(position, size.height, size.width);
      pages[page].drawImage(image, { ...pos, width: 55, height: 55 });

      pages[page].drawText(`For verification, please visit:`, {
        font: docFont,
        lineHeight: 12,
        size: 7,
        maxWidth: 150,
        x: pos.x - 20,
        y: pos.y - 2,
        color: rgb(0.5, 0.5, 0.5),
      });

      pages[page].drawText(`https://dms.judiciary.gov.ph`, {
        font: docFont,
        lineHeight: 12,
        size: 7,
        maxWidth: 150,
        x: pos.x - 20.3,
        y: pos.y - 8,
        color: rgb(0, 0, 0),
      });

      const link = pages[page].doc.context.register(
        pages[page].doc.context.obj({
          Type: 'Annot',
          Subtype: 'Link',
          Rect: [pos.x, pos.y + 55, pos.x + 55, pos.y - 10],
          Border: [0, 0, 0],
          C: [0, 0, 1],
          A: {
            Type: 'Action',
            S: 'URI',
            URI: PDFString.of(`https://dms.judiciary.gov.ph`),
          },
        })
      );

      pages[page].node.set(PDFName.of('Annots'), pdfDoc.context.obj([link]));
    }

    this.pdfSrc = await pdfDoc.save();
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    const { target } = event;
    this.pdfViewer.pdfViewerContainer.nativeElement.scrollTop =
      target.scrollTop;
  }

  save() {
    this.showQRModal = 'none';
    this._loader.show();
    const file = this._sign.bytesToFile(
      this.pdfSrc,
      this._fileActionService?.file?.name ?? 'updated-file'
    );

    this._fileService
      .updateFile(file, this._fileActionService?.file?.id)
      .subscribe(i => {
        this.fileLink = `./assets/${i.data.path}`;

        this._fileService
          .updateQRAdded(this._fileActionService?.file?.id, true, this.privacy)
          .subscribe(() => {
            this._loader.hide();
            this.disableQRButton = true;
            this.fileData = this.pdfSrc;
          });
      });
  }

  saveWatermark() {
    this.showWatermarkModal = 'none';
    this._loader.show();
    const file = this._sign.bytesToFile(
      this.pdfSrc,
      this._fileActionService?.file?.name ?? 'updated-file'
    );

    this._fileService
      .updateFile(file, this._fileActionService?.file?.id)
      .subscribe(i => {
        this.fileLink = `./assets/${i.data.path}`;

        this._fileService
          .watermarkDirectory(this._fileActionService?.file?.id)
          .subscribe(() => {
            this.disableWatermarkButton = true;
            this._loader.hide();
            this.fileData = this.pdfSrc;
          });
      });
  }

  print() {
    try {
      // Listen for the afterprint event
      window.onafterprint = async () => {
        await this.logPrinting();
      };
    } catch {}

    try {
      this.pdf.getData().then(async (u8: any) => {
        let blob = new Blob([u8.buffer], {
          type: 'application/pdf',
        });

        const blobUrl = window.URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);

        iframe.contentWindow?.print();
        await this.logPrinting();
      });
    } catch (e) {
      console.log('ERROR', e);
      window.print();
    }
  }

  getLink() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.fileLink);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (
      event.key === 'p' &&
      ((event.ctrlKey && !event.metaKey) ||
        (event.metaKey && !event.ctrlKey)) &&
      !event.shiftKey
    ) {
      event.preventDefault();
      this.print();
    }
  }

  async logPrinting() {
    if (this._fileActionService?.file?.id) {
      await lastValueFrom(
        this._fileService.printDirectory(this._fileActionService?.file?.id)
      );
    }
  }

  //watermark functions
  watermarkTextChanged(event: any) {
    console.log('text changed');
    this.addWatermarkV2();
  }
  watermarkPositionChanged(event: any) {
    console.log('text position');
    this.addWatermarkV2();
  }
  watermarkColorChanged(event: any) {
    console.log('text color changed', event);
    if (this.watermarkColor !== event) {
      this.watermarkColor = event;
      this.addWatermarkV2();
    }
  }
  watermarkOpacityChanged(event: any) {
    console.log('text opacity changed');
    this.addWatermarkV2();
  }
  watermarkTextDirectionChanged(event: any) {
    console.log('text derection changed');
    this.addWatermarkV2();
  }

  watermarkShowMarkedChanged(event: any) {
    console.log('text marked changed');
    this.watermarkShowMarked = event.checked;
    this.addWatermarkV2();
  }

  watermarkShowSharedChanged(event: any) {
    console.log('text shared changed');
    this.watermarkShowShared = event.checked;
    this.addWatermarkV2();
  }

  async addWatermark() {
    const pdfDoc = await PDFDocument.load(this.fileData);
    const pages = pdfDoc.getPages();
    const { width, height } = pages[0].getSize();
    const fontSize = 78;

    const fontBytes = await fetch('../../../assets/fonts/DMSans.ttf').then(
      res => res.arrayBuffer()
    );
    pdfDoc.registerFontkit(fontkit);
    const watermarkFont = await pdfDoc.embedFont(fontBytes);

    const color = this.hexToRgb(this.watermarkColor) ?? rgb(0.9, 0.9, 0.9);
    const opacity = parseFloat(this.watermarkOpacity);
    const adjustedColor = rgb(
      color.red + (1 - color.red) * (1 - opacity),
      color.green + (1 - color.green) * (1 - opacity),
      color.blue + (1 - color.blue) * (1 - opacity)
    );

    const textLength = this.watermarkText.length;
    const adjustFS = Math.max(50, Math.min(78, width / textLength)); // adjust the font size based on the text length

    const watermarkedByText = `Watermarked By: ${this._auth.email}`;
    const watermakedByFS = Math.max(
      adjustFS * 0.7,
      Math.min(72, width / watermarkedByText.length)
    ); // adjust the font size based on the text length

    const sharedWithText = `Shared with: ${[
      ...this._fileActionService?.file?.users,
      ...this._fileActionService?.file?.groups,
    ]
      .map((user: any) => user?.email || user?.name)
      .join(', ')}`;

    const wordBreaks = pdfDoc.defaultWordBreaks;
    const textWidth = (t: string) =>
      watermarkFont.widthOfTextAtSize(t, fontSize);
    const lines = breakTextIntoLines(
      this.watermarkText,
      wordBreaks,
      width + 100,
      textWidth
    );
    const lins = lineSplit(cleanText(this.watermarkText));

    console.log({ lines, lins, wordBreaks });
    const { x, y } = this.getWatermarkLocation(
      width,
      height,
      fontSize,
      watermarkFont,
      lines[0]
    );

    pages.forEach(page => {
      lines.forEach((line, index) => {
        const nextY = y;
        const { x: newX, y: calcY } = this.getWatermarkLocation(
          width,
          height,
          fontSize,
          watermarkFont,
          line
        );
        page.drawText(line, {
          x: newX,
          y: calcY - index * adjustFS * 1.2,
          size: adjustFS,
          font: watermarkFont,
          color: adjustedColor,
          rotate: degrees(this.watermarkTextDirection === 'diagonal' ? 45 : 0),
          maxWidth: width,
          lineHeight: adjustFS * 1.1,
          wordBreaks,
        });
      });

      // Calculate the number of lines in the current text block
      const numberOfLines = lines.length;

      // Calculate the y-coordinate for the next block of text
      const nextY = y - numberOfLines * adjustFS;
      if (this.watermarkShowMarked) {
        const { x: newX, y: calcY } = this.getWatermarkLocation(
          width,
          height,
          fontSize,
          watermarkFont,
          watermarkedByText
        );
        page.drawText(watermarkedByText, {
          x: newX,
          y: nextY,
          size: watermakedByFS,
          font: watermarkFont,
          color: adjustedColor,
          rotate: degrees(this.watermarkTextDirection === 'diagonal' ? 45 : 0),
          maxWidth: width + x + 100,
          lineHeight: watermakedByFS * 1.1,
          wordBreaks,
        });
      }

      const numberOfLinesMarked = Math.ceil(
        (watermarkedByText.length * watermakedByFS) / width
      );

      const nextYMarked = nextY - numberOfLinesMarked * watermakedByFS * 0.8;
      if (this.watermarkShowShared) {
        const { x: newX, y: calcY } = this.getWatermarkLocation(
          width,
          height,
          fontSize,
          watermarkFont,
          sharedWithText
        );

        const wordBreaks = pdfDoc.defaultWordBreaks;
        const textWidth = (t: string) =>
          watermarkFont.widthOfTextAtSize(t, fontSize);
        const lines = breakTextIntoLines(
          sharedWithText,
          wordBreaks,
          width + 100,
          textWidth
        );
        const lins = lineSplit(cleanText(sharedWithText));

        console.log({ lines, lins });
        page.drawText(sharedWithText, {
          x,
          y: nextYMarked,
          size: watermakedByFS,
          font: watermarkFont,
          color: adjustedColor,
          rotate: degrees(this.watermarkTextDirection === 'diagonal' ? 45 : 0),
          maxWidth: width + x + 100,
          lineHeight: watermakedByFS * 1.1,
          wordBreaks: [...wordBreaks, ','],
        });
      }
    });

    this.pdfSrc = await pdfDoc.save({ useObjectStreams: false });
  }

  hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? rgb(
          parseInt(result[1], 16) / 255,
          parseInt(result[2], 16) / 255,
          parseInt(result[3], 16) / 255
        )
      : null;
  }

  getWatermarkLocation(
    width: any,
    height: any,
    fontSize: any,
    font: any,
    text = this.watermarkText
  ) {
    const textWidth = font.widthOfTextAtSize(text, fontSize);
    const textHeight = font.sizeAtHeight(fontSize);

    switch (this.watermarkPosition) {
      case 'center':
        const centerX = width / 2 - textWidth / 4;
        const centerY = height / 2;
        return { x: centerX, y: centerY };
      case 'top':
        const topX = width / 2 - textWidth / 4;
        const topY = height - textHeight; // subtract fontSize to account for the height of the text
        return { x: topX, y: topY };
      case 'bottom':
        const bottomX = width / 2 - textWidth / 4;
        const bottomY = fontSize; // set it to fontSize so the text doesn't get cut off at the bottom
        return { x: bottomX, y: bottomY };
    }

    const centerX = width / 2 - textWidth / 4;
    const centerY = height / 2;
    return { x: centerX, y: centerY };
  }

  async showWatermarkScreen() {
    if (this.disableWatermarkButton) {
      return;
    }

    if (this.showWatermarkModal === 'none') {
      await this.addWatermarkV2();

      const height =
        this.pdfViewer.pdfViewerContainer?.nativeElement?.scrollHeight;
      this.overlayHeight = `${height + 90}px`;
      console.log({ height: this.overlayHeight });
      this.showWatermarkModal = 'flex';
    } else if (this.showWatermarkModal === 'flex') {
      this.showWatermarkModal = 'none';
      this.pdfSrc = this.fileData;
    }
  }

  async addWatermarkV2() {
    if (!this.watermarkText.length) return;

    const pdfDoc = await PDFDocument.load(this.fileData);
    const pages = pdfDoc.getPages();
    const fontBytes = await fetch('../../../assets/fonts/DMSans.ttf').then(
      res => res.arrayBuffer()
    );
    pdfDoc.registerFontkit(fontkit);
    const watermarkFont = await pdfDoc.embedFont(fontBytes);

    const color = this.hexToRgb(this.watermarkColor) ?? rgb(0.9, 0.9, 0.9);
    const opacity = parseFloat(this.watermarkOpacity);
    const adjustedColor = rgb(
      color.red + (1 - color.red) * (1 - opacity),
      color.green + (1 - color.green) * (1 - opacity),
      color.blue + (1 - color.blue) * (1 - opacity)
    );

    console.log({ pages, file: this._fileActionService?.file });
    for (const page of pages) {
      try {
        const isDiagonal = this.watermarkTextDirection === 'diagonal';
        const rotationAngle = isDiagonal ? degrees(45) : degrees(0);
        const { width, height } = page.getSize();
        const fontSize = this.calculateFontSize(width, this.watermarkText);
        let lines = this.splitTextToLines(
          this.watermarkText,
          width,
          watermarkFont,
          fontSize
        );

        let fs = fontSize;
        const markedText = `Watermarked by: ${this._auth.email}`;
        const markedFontSize = 21;
        const markedLines = this.splitTextToLines(
          markedText,
          width,
          watermarkFont,
          markedFontSize
        );

        const sharedText = `Shared with: ${[
          ...this._fileActionService?.file?.groups,
          ...this._fileActionService?.file?.users,
        ]
          .map(user => user.email || user.name)
          .join(', ')}`;
        const sharedFontSize = 21;
        const sharedLines = this.splitTextToLines(
          sharedText,
          width,
          watermarkFont,
          sharedFontSize
        );

        let totalLines = lines.length;
        let addSpace = fontSize * lines.length;
        console.log({ totalLines });
        if (this.watermarkShowMarked) {
          totalLines += markedLines.length;
          addSpace += markedFontSize * markedLines.length;
          console.log({ totalLines });
          fs = markedFontSize;
        }

        if (this.watermarkShowShared) {
          totalLines += sharedLines.length;
          addSpace += sharedFontSize * sharedLines.length;
          console.log({ totalLines });
          fs = sharedFontSize;
        }

        const {
          x: boxX,
          y: boxY,
          boxWidth,
          boxHeight,
        } = this.calculateBoxPosition(
          width,
          height,
          this.watermarkPosition,
          rotationAngle,
          totalLines,
          sharedFontSize
        );

        console.log({
          width,
          height,
          watermarkPosition: this.watermarkPosition,
          rotationAngle,
          totalLines,
          sharedFontSize,
        });

        let yOffset =
          boxY + (this.watermarkPosition == 'bottom' ? addSpace : addSpace / 2);
        let xOffsetMain = 0;
        lines.forEach((line, index) => {
          const textWidth = watermarkFont.widthOfTextAtSize(line, fontSize);

          const xOffset = isDiagonal ? fontSize / Math.sqrt(index + 1) : 0;
          console.log({ yOffset, textWidth });
          let x;
          if (isDiagonal && xOffset) {
            xOffsetMain += fontSize;
            x = xOffsetMain;
          } else {
            x = boxX + (width - textWidth) / 2 + xOffset;
            xOffsetMain = x;
          }

          const y = yOffset;

          page.drawText(line, {
            x: x,
            y: y,
            size: fontSize,
            font: watermarkFont,
            color: color,
            rotate: rotationAngle,
            maxWidth: textWidth,
            opacity: +this.watermarkOpacity,
          });

          yOffset = yOffset - fontSize + fontSize * 0.1; // Adjust Y-offset for the next line
        });

        if (this.watermarkShowMarked) {
          markedLines.forEach((line, index) => {
            const textWidth = watermarkFont.widthOfTextAtSize(
              line,
              markedFontSize
            );
            const xOffset = isDiagonal
              ? fontSize / Math.sqrt(lines.length + index)
              : 0;
            xOffsetMain = xOffsetMain + markedFontSize;
            const x = isDiagonal
              ? xOffsetMain
              : boxX + (boxWidth - textWidth) / 2 + xOffset + 10;

            const y = yOffset;

            page.drawText(line, {
              x: x,
              y: y,
              size: markedFontSize,
              font: watermarkFont,
              color: color,
              rotate: rotationAngle,
              maxWidth: textWidth,
              opacity: +this.watermarkOpacity,
            });

            yOffset -= markedFontSize + fontSize * 0.1; // Adjust Y-offset for the next line
          });
        }

        if (this.watermarkShowShared) {
          sharedLines.forEach((line, index) => {
            const textWidth = watermarkFont.widthOfTextAtSize(
              line,
              sharedFontSize
            );
            const xOffset = isDiagonal
              ? fontSize / Math.sqrt(lines.length + index)
              : 0;
            xOffsetMain = xOffsetMain + markedFontSize;
            const x = isDiagonal
              ? xOffsetMain
              : boxX + (boxWidth - textWidth) / 2 + xOffset + 10;
            const y = yOffset;

            page.drawText(line, {
              x: x,
              y: y,
              size: sharedFontSize,
              font: watermarkFont,
              color: color,
              rotate: rotationAngle,
              maxWidth: textWidth,
              opacity: +this.watermarkOpacity,
            });

            yOffset -= sharedFontSize + fontSize * 0.1; // Adjust Y-offset for the next line
          });
        }
      } catch (error) {
        console.log({ error });
      }
    }

    this.pdfSrc = await pdfDoc.save();
  }

  calculateBoxPosition(
    pageWidth: number,
    pageHeight: number,
    position: string,
    rotationAngle: any,
    totalLines: number,
    fontSize: any
  ) {
    const boxWidth = pageWidth; // 80% of page width
    const boxHeight = pageHeight * 0.2; // 20% of page height
    let boxX = (pageWidth - boxWidth) / 2; // Center the box horizontally
    let isDiagonal = false;
    if (rotationAngle.angle === 45) {
      isDiagonal = true;
    }
    let boxY;

    let magicNumber;
    console.log({ pageHeight });
    if (pageHeight > 1000) {
      magicNumber =
        (boxHeight / Math.sqrt(totalLines)) *
        (this.watermarkShowMarked || this.watermarkShowShared
          ? 2.9
          : totalLines == 1
          ? 1.4
          : 2.1);
    } else {
      magicNumber =
        (boxHeight / Math.sqrt(totalLines)) *
        (this.watermarkShowMarked || this.watermarkShowShared
          ? 4.3
          : totalLines == 1
          ? 1.5
          : 2.8);
    }

    switch (position) {
      case 'top':
        boxY = pageHeight - boxHeight - (isDiagonal ? magicNumber : 0); // Adjusted to be closer to the top
        break;
      case 'center':
        boxY =
          (pageHeight - boxHeight) / 2 -
          (isDiagonal ? boxHeight / Math.sqrt(totalLines) : 0);
        break;
      case 'bottom':
        boxY = isDiagonal
          ? (boxHeight / Math.sqrt(totalLines) / totalLines) * 0.1
          : (fontSize * totalLines) / 1.5; // Ensure it doesn't go out of bounds
        break;
      default:
        boxY = 0;
    }

    return { x: boxX, y: boxY, boxWidth, boxHeight };
  }

  calculateFontSize(
    pageWidth: any,
    text: any,
    maxFontSize = 78,
    minFontSize = 50
  ) {
    const textLength = text.length;
    return Math.max(
      minFontSize,
      Math.min(maxFontSize, (pageWidth / textLength) * 2)
    );
  }

  splitTextToLines(text: any, pageWidth: any, font: any, fontSize: any) {
    const words = text.split(' ');
    const lines = [];
    let currentLine = words[0];

    words.slice(1).forEach((word: any) => {
      const testLine = currentLine + ' ' + word;
      const metrics = font.widthOfTextAtSize(testLine, fontSize);
      if (metrics > pageWidth) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine = testLine;
      }
    });

    lines.push(currentLine); // Push the last line
    return lines;
  }
}

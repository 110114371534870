<div class="image-background wrapper">
  <div class="content-wrapper">
    <ng-content></ng-content>
  </div>
  <div class="footer">
    <div class="footer-logo">
      <span>Powered by:</span>
      <img src="../../../assets/images/unawa-logo-text.png" alt="" />
    </div>
    <p class="footer-text">
      <a
        class="emphasize"
        href="https://sign-enterprise-sc-staging.web.app/terms-and-conditions"
        target="_blank"
        >Terms & Conditions</a
      >
      |
      <a
        class="emphasize"
        href="https://sign-enterprise-sc-staging.web.app/privacy-policy"
        target="_blank"
        >Privacy Policy</a
      ><br />
      © 2025
      <a class="emphasize" href="https://www.unawa.asia/" target="_blank"
        >UNAWA</a
      >
      . All rights reserved<br />
    </p>
  </div>
</div>

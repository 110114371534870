<ng-container>
  <ng-container *ngIf="isPhoneWidth || isTabletWidth; else actionDashData">
    <div class="statistics">
      <app-progress-card [image]="'../../../assets/images/icons/file-icon.png'" [title]="'All Documents'"
        [number]="dashData?.allDocuments" [isPhoneWidth]="isPhoneWidth || isTabletWidth ? true : false"
        (filterEvent)="filterDashboardData('ALL', 'All Documents')" [selected]="selected"></app-progress-card>
      <app-progress-card [image]="'../../../assets/images/icons/drafts-icon.svg'" [title]="'For My Action'"
        [number]="dashData?.forMyAction" [isPhoneWidth]="isPhoneWidth || isTabletWidth ? true : false"
        [textColor]="'#E75151'" (filterEvent)="filterDashboardData('FOR_MY_ACTION', 'For My Action')"
        [selected]="selected"></app-progress-card>
      <app-progress-card [image]="'../../../assets/images/icons/pending-icon.svg'" [title]="'For Others’ Action'"
        [number]="dashData?.forOtherAction" [isPhoneWidth]="isPhoneWidth || isTabletWidth ? true : false" (filterEvent)="
          filterDashboardData('FOR_OTHER_ACTION', 'For Others’ Action')
        " [selected]="selected"></app-progress-card>
      <app-progress-card [image]="'../../../assets/images/icons/signed-icon.svg'" [title]="'Completed'"
        [number]="dashData?.completed" [isPhoneWidth]="isPhoneWidth || isTabletWidth ? true : false"
        [textColor]="'#23AC00'" (filterEvent)="filterDashboardData('COMPLETED', 'Completed')"
        [selected]="selected"></app-progress-card>
    </div>
  </ng-container>
  <div class="recents {{ userType }}">
    <app-table [title]="signSecureDocumentTitle" [data]="signSecureDocumentDataAsync | async"
      [columns]="signSecureDocumentCols" [canOpenFile]="true" [type]="'recent-documents'"
      (modalCloses)="recentDocumentModal($event)" [rowFunction]="openSignSecureDoc" [paginate]="true"
      [addButton]="'Use SignSecure'" [addButtonIcon]="'../../../../assets/images/signature-icon.svg'"
      (modal)="useSignSecure()" class="signsecure">
      <div class="tableSearch">
        <div class="search-form">
          <mat-form-field floatLabel="never" appearance="fill">
            <input matInput type="text" placeholder="Input Your Text in Here" [(ngModel)]="searchQuery"
              (keydown.enter)="search()" />
            <span matPrefix><img class="search-image" src="../../../assets/images/icons/search.png" alt="" /></span>
          </mat-form-field>
          <button type="submit" class="search-button" (click)="search()">
            Search
          </button>
        </div>
      </div>
    </app-table>

    <app-tablet-table [itemTemplate]="recent_document_row" [title]="signSecureDocumentTitle"
      [data]="signSecureDocumentDataAsync | async">
      <button class="button" (click)="useSignSecure()" *ngIf="isTabletWidth">
        <img [src]="'../../../../assets/images/signature-icon.svg'" alt="" />
        Create Document
      </button>
    </app-tablet-table>

    <!-- <app-tablet-table
      [itemTemplate]="recent_document_row"
      [title]="recentDocmentTitle"
      [data]="recentDocumentData | async"
    ></app-tablet-table>
    <app-tablet-table
      *ngIf="userType === 'admin'"
      [itemTemplate]="recent_user_row"
      [title]="recentUsersTitle"
      [data]="recentUsersData"
    ></app-tablet-table>
    <div class="recent-admin">
      <app-table
        [title]="recentDocmentTitle"
        [data]="recentDocumentData | async"
        [columns]="recentDocuemtCols"
        [canOpenFile]="true"
        [type]="'recent-documents'"
        (modalCloses)="recentDocumentModal($event)"
        [nameFunction]="openPdf"
        [paginate]="true"
        class="recents"
      ></app-table>
      <app-table
        *ngIf="userType === 'admin'"
        [title]="recentUsersTitle"
        [data]="recentUsersData"
        [columns]="recentUsersCols"
        [paginate]="true"
        class="recents"
      ></app-table>
    </div>
    <app-table
      *ngIf="userType === 'user'"
      [title]="'Recent Folders'"
      [data]="recentFolders"
      [columns]="recentFoldersCols"
      [rowFunction]="openFolder"
      (modalCloses)="recentDocumentModal($event)"
      [paginate]="true"
    ></app-table>
    <app-tablet-table
      *ngIf="userType === 'user'"
      [itemTemplate]="recent_document_row"
      [title]="'Recent Documents'"
      [data]="recentDocumentData | async"
    ></app-tablet-table> -->
  </div>
</ng-container>

<ng-template #recent_document_row let-data>
  <div class="recent-document-row">
    <div class="left-content">
      <div class="recent-document__content" (click)="rowFunction(data, data)">
        <p class="title">{{ data.name }}</p>
        <p class="subtitle">{{ data.status }}</p>
        <p class="content">
          <a>Updated on: </a>{{
          data.updatedAtFormatted || data.updatedAt | date : "dd MMM YYYY"
          }}
        </p>
      </div>
      <p class="delete-action" *ngIf="data.actions?.delete_doc" (click)="showModal('deleteDocument', '90vw', data)">
        Delete
      </p>
      <app-actions [actions]="data.actions" [data]="data"></app-actions>
    </div>
    <div class="recent-document__img" (click)="rowFunction(data, data)">
      <img src="../../../../assets/images/arrow-right.svg" alt="" srcset="" />
    </div>
  </div>
</ng-template>

<ng-template #recent_user_row let-data>
  <div class="recent-user-row">
    <div class="recent-user__content">
      <p class="title">{{ data.name }}</p>
      <p class="subtitle">{{ data.email }}</p>
      <p class="content"><span>Added on: </span>{{ data.date_added }}</p>
    </div>
    <div class="recent-user__img">
      <!-- <img src="../../../../assets/images/arrow-right.svg" alt="" srcset="" /> -->
    </div>
  </div>
</ng-template>

<ng-template #actionDashData>
  <div class="statistics">
    <app-progress-card [actionData]="actionData | async" [selected]="filterType"
      [isPhoneWidth]="isPhoneWidth || isTabletWidth ? true : false"
      (filterEvent)="filterDashboardData($event)"></app-progress-card>
  </div>
</ng-template>
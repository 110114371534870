<div>
  <mat-card class="container-login">
    <div class="flexGrid">
      <h2 class="heading2">Welcome Back</h2>
      <h4 class="heading4">Enter your email and password below</h4>
    </div>
    <form name="loginForm" [formGroup]="loginForm">
      <div class="flex padding-top20">
        <mat-label class="label">Email</mat-label>
      </div>
      <div class="flex padding-left">
        <mat-form-field class="form login-form" floatLabel="never" appearance="fill">
          <input type="email" placeholder="Enter Email Here" matInput formControlName="username"
            (ngModelChange)="errorMsg = ''" [ngClass]="
              (loginForm.controls['username'].invalid || errorMsg) &&
              loginForm.controls['username'].value?.length > 0 &&
              loginForm.controls['username'].touched
                ? 'textfieldRegInvalid'
                : 'textfieldReg'
            " />
          <ng-container *ngIf="loginForm.controls['username']">
            <mat-error *ngIf="
                loginForm.controls['username'].value?.length <= 0 &&
                loginForm.controls['username'].dirty
              ">Email Address is Required</mat-error>
            <mat-error *ngIf="
                loginForm.controls['username'].invalid &&
                loginForm.controls['username'].value?.length > 0
              ">Email Address format is invalid</mat-error>
            <mat-error class="longError" *ngIf="
                errorMsg && loginForm.controls['username'].value?.length > 0
              ">{{ errorMsg }}</mat-error>
          </ng-container>
        </mat-form-field>
      </div>
      <div class="flex padding-top20">
        <mat-label class="label">Password</mat-label>
      </div>
      <div class="flex padding-left">
        <mat-form-field class="form login-form password" floatLabel="never" appearance="fill">
          <input [type]="hide ? 'password' : 'text'" placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤" matInput formControlName="password"
            [ngClass]="
              loginForm.controls['password'].invalid &&
              loginForm.controls['password'].touched
                ? 'textfieldRegInvalid'
                : 'textfieldReg'
            " (keydown.enter)="login()" />
          <mat-icon matSuffix (click)="hide = !hide">{{
            hide ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          <ng-container *ngIf="loginForm.controls['password'].touched">
            <mat-error *ngIf="loginForm.controls['password'].value?.length == 0">Password is Required</mat-error>
            <mat-error *ngIf="
                loginForm.controls['password'].invalid &&
                loginForm.controls['password'].value?.length > 0
              ">Password is Invalid</mat-error>
          </ng-container>
        </mat-form-field>
      </div>
    </form>
    <div class="remember-pair">
      <div class="">
        <mat-checkbox [(ngModel)]="rememberMe">Remember Me</mat-checkbox>
      </div>

      <div class="">
        <label class="label-fpw" (click)="goTo('recoverPassword')">Forgot Password</label>
      </div>
    </div>

    <div class="flex padding-left padding padding-top40">
      <button class="signin-btn" (click)="login()">
        <label class="btn-font">Sign In</label>
      </button>
    </div>

    <div class="flex padding-left padding-top-16">
      <button class="ad-signin-btn" (click)="loginAD()">
        <label class="btn-font-ad"><img src="../../../assets/images/ad-logo.png" /> Sign in with PJ365</label>
      </button>
    </div>

    <div class="flex justify-space-between padding-top20">
      <div class="" *ngIf="regFlag">
        <label>Don't have an account yet? </label>
        &nbsp;
        <label class="label-clickable" (click)="goTo('registration')">Sign up here</label>
      </div>
      <div class="scan-qr" (click)="scanQR()">
        <span class="text">Scan QR code</span>
        <img src="../../../assets/images/qr-code.png" alt="" />
      </div>
    </div>
  </mat-card>
</div>
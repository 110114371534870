import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { SignSecureService } from 'src/app/@shared/services/signsecure.service';
import {
  PageSizes,
  PDFDict,
  PDFDocument,
  PDFFont,
  PDFName,
  PDFPage,
  PDFString,
  rgb,
  RotationTypes,
} from 'pdf-lib';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import fontkit from '@pdf-lib/fontkit';
import { from, lastValueFrom, Subject, Subscription, tap } from 'rxjs';
import moment from 'moment';
import { MatSidenav } from '@angular/material/sidenav';
import {
  ComponentPortal,
  ComponentType,
  Portal,
  TemplatePortal,
} from '@angular/cdk/portal';
import { UsersService } from 'src/app/@shared/services/users.service';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { FilesService } from 'src/app/@shared/services/files.service';
import { QrService } from 'src/app/@shared/services/qr.service';
import { NavigationStart, Router } from '@angular/router';
import * as momentTimezone from 'moment-timezone';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-signing-page',
  templateUrl: './signing-page.component.html',
  styleUrls: ['./signing-page.component.sass'],
})
export class SigningPageComponent implements OnInit {
  data: any;

  navdata: any[] = [];

  done: boolean = false;
  cancel: boolean = false;
  role: string = '';

  declined: boolean = false;
  doneData: any;
  status: any;
  createdByMe: boolean = false;
  documentStatus: string = '';

  openDrawer: boolean = false;
  auditTrail: any[] = [];
  action: string = '';

  @ViewChild('sidenav', { static: true })
  sidenav!: MatSidenav;

  private panelPortal$ = new Subject<Portal<any>>();
  panelPortal = from(this.panelPortal$);
  @ViewChild('AuditTrail', { static: true }) auditTailRef!: TemplateRef<any>;
  @ViewChild('DocumentDetails', { static: true })
  documentDetailsRef!: TemplateRef<any>;
  @ViewChild('Default', { static: true }) defaultRef!: TemplateRef<any>;

  signatures: any[] = [];
  pdfObject: any;
  alreadyViewed: boolean = false;
  signNow: boolean = false;
  currentUser: any;
  expired: boolean = false;
  viewed: boolean = false;

  subscription: Subscription = new Subscription();
  constructor(
    private _signSecure: SignSecureService,
    private _loader: LoaderService,
    private _auth: AuthenticationService,
    private vcf: ViewContainerRef,
    private _user: UsersService,
    private _file: FilesService,
    private _qr: QrService,
    private change: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this._signSecure.worflowData$.subscribe(async workflowData => {
      const workflow = workflowData?.workflow;
      if (!this.viewed) {
        await lastValueFrom(this._file.viewDirectory(workflowData.id));
        this.viewed = true;
      }

      this.data = workflowData;
      this.navdata = workflow?.signatures ?? [];
      this.signatures = [...(workflow?.signatures ?? [])];

      const party = workflow?.parties?.find(
        (party: any) => party.id === this._auth.userId
      );

      this.role = party?.role ?? 'SIGN';
      this.status = party?.status;
      this.auditTrail = workflow?.auditTrail ?? [];

      this.currentUser = workflow?.parties
        ? workflow?.parties[workflow.currentOrder ?? 0]
        : {};

      this.signNow =
        (!workflow?.signOrder &&
          !!workflow?.parties?.some(party => party.id === this._auth.userId)) ||
        workflow?.currentParty === this._auth.userId;
      this.createdByMe = workflowData.createdBy === this._auth.userId;

      this.cancel =
        this.data?.status === 'CANCELLED' ||
        workflow?.status === 'CANCELLED' ||
        (this.data?.status === 'EXPIRED' && !this.createdByMe);
      this.expired = this.data?.status === 'EXPIRED';

      this.documentStatus = workflow?.status ?? '';

      if (
        !!party?.executedFormatted ||
        !!workflow?.parties?.some((item: any) => item.status === 'DECLINED')
      ) {
        this.status = 'COMPLETED';
      } else {
        this.status = workflow?.status ?? '';
      }

      if (!party?.viewedFormatted && !this.alreadyViewed) {
        this.alreadyViewed = true;
        this._signSecure
          .documentView(workflowData?.id)
          .subscribe(({ data }) => {
            this.auditTrail.length = 0;
            this.auditTrail.push(...data.workflow.auditTrail);
            this.data = data;
          });
      }

      let path = '../../../assets/dms-documents/pdf-test.pdf';
      if (!environment.local) {
        path = `./assets/${this.data.path}`;
      }

      const bytes = await fetch(path).then(res => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(bytes);
      this.checkIfAllSignatures(pdfDoc, this.data, true);
    });
  }

  updateData(event: any) {
    this.navdata = event;
  }

  //
  async generateNewPdfFile() {
    this._loader.show();
    await this.getUpdatedDocument();
    await this.generateSignedPdf();
    this._loader.hide();
  }

  async getUpdatedDocument() {
    this.data = (
      await lastValueFrom(this._file.getFile(this.data?.id || this.data?._id))
    )?.data;
  }

  async generateSignedPdf() {
    let path = '../../../assets/dms-documents/pdf-test.pdf';
    if (!environment.local) {
      path = `./assets/${this.data.path}`;
    }

    const bytes = await fetch(path).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(bytes);
    const pages = pdfDoc.getPages();

    const pagesLength = this.navdata.length;
    //fonts
    const fontBytes = await fetch('../../../assets/fonts/DMSans.ttf').then(
      res => res.arrayBuffer()
    );
    const fontItalicBytes = await fetch(
      '../../../assets/fonts/DMSans-Italic.ttf'
    ).then(res => res.arrayBuffer());
    const fontBoldBytes = await fetch(
      '../../../assets/fonts/DMSans-Bold.ttf'
    ).then(res => res.arrayBuffer());
    const logoImage = await fetch(
      '../../../assets/images/signature-logo.png'
    ).then(res => res.arrayBuffer());
    const yohaku = 50 / PdfViewerComponent.CSS_UNITS;

    let text = this.data.id;
    text = this._qr.encrypt(text);
    const qrCode = await this._qr.getQRCode(text);

    for (let page = 0; page < pagesLength; page++) {
      try {
        const signatures = this.navdata[page];
        const length = signatures?.length ?? 0;
        pages[page]?.doc.registerFontkit(fontkit);
        const annotations = pages[page].node.Annots();
        console.log({ annotations });
        const docFont = await pages[page]?.doc.embedFont(fontBytes);
        const docItalicFont = await pages[page]?.doc.embedFont(fontItalicBytes);
        for (let i = 0; i < length; i++) {
          const signature = signatures[i];
          const pageSize = pages[page].getSize();

          // Draw signature data
          if (signature.signatureData) {
            // draw signature
            let image = await pages[page]?.doc?.embedPng(
              signature.signatureData
            );
            console.log({
              yLocation: +signature?.signatureConfig?.y,
              signature,
              pageSize,
            });
            const signatureConfig = signature?.signatureConfig;
            const y =
              pageSize.height -
              +signatureConfig?.y / PdfViewerComponent.CSS_UNITS -
              25;
            //this.getYLocation(+signature?.signatureConfig?.y, signature.pageSize.height, pageSize.height) - 15
            const x = +signatureConfig?.x / PdfViewerComponent.CSS_UNITS + 5;
            //this.getXLocation(+signature?.signatureConfig?.x, signature.pageSize.width, pageSize.width)
            const config = {
              x,
              y,
              width:
                +signature.signatureConfig?.width /
                PdfViewerComponent.CSS_UNITS,
              height:
                (+signature.signatureConfig?.height *
                  +signature.signatureConfig?.scaleY) /
                PdfViewerComponent.CSS_UNITS,
            };
            pages[page].drawImage(image, config);

            // draw logo
            if (signature.sigData) {
              let image = await pages[page]?.doc?.embedPng(logoImage);
              const y = pageSize.height - +signature?.sigData?.y - 10; // this.getYLocation(+signature?.sigData?.y, signature.pageSize.height, pageSize.height)
              const x = +signature?.sigData?.x; //this.getXLocation(+signature?.sigData?.x, signature.pageSize.width, pageSize.width)
              const config = {
                x: x + 13 / PdfViewerComponent.CSS_UNITS,
                y: y - 10,
                width: +signature.sigData?.width,
                height: +signature.sigData?.height,
              };
              pages[page].drawImage(image, config);

              // draw document id
              let documentConfig = {
                x: x + 125, // this.getYLocation(+signature?.documentConfig?.x, signature.pageSize.width, pageSize.width),
                y: y, //- 8 / PdfViewerComponent.CSS_UNITS, //this.getYLocation(+signature?.documentConfig?.y, signature.pageSize.height, pageSize.height) + 23,
                font: docFont,
                size:
                  +signature?.documentConfig?.fontSize /
                    PdfViewerComponent.CSS_UNITS ?? 5,
              };
              pages[page].drawText(this.data.id, documentConfig);
            }
          }

          if (signature.nameConfig) {
            const nameConfig = {
              x: (+signature.nameConfig.x + 8) / PdfViewerComponent.CSS_UNITS,
              y:
                pageSize.height -
                +signature.nameConfig.y / PdfViewerComponent.CSS_UNITS -
                7,
              font: docFont,
              size:
                +signature.nameConfig?.fontSize /
                  PdfViewerComponent.CSS_UNITS ?? 5,
            };
            pages[page].drawText(signature.nameConfig?.text ?? '', nameConfig);
          }

          if (signature.dateConfig) {
            const dateConfig = {
              x: (+signature.dateConfig.x + 8) / PdfViewerComponent.CSS_UNITS,
              y:
                pageSize.height -
                +signature.dateConfig.y / PdfViewerComponent.CSS_UNITS -
                7,
              font: docFont,
              size:
                +signature.dateConfig?.fontSize /
                  PdfViewerComponent.CSS_UNITS ?? 5,
            };
            pages[page].drawText(
              moment().format('DD/MM/YYYY hh:mm A'),
              dateConfig
            );
          }

          if (signature.desginationConfig) {
            const desginationConfig = {
              x:
                (+signature.desginationConfig.x + 8) /
                PdfViewerComponent.CSS_UNITS,
              y:
                pageSize.height -
                +signature.desginationConfig.y / PdfViewerComponent.CSS_UNITS -
                7,
              font: docItalicFont,
              size:
                +signature.desginationConfig?.fontSize /
                  PdfViewerComponent.CSS_UNITS ?? 5,
            };
            pages[page].drawText(
              signature.desginationConfig?.text ?? '',
              desginationConfig
            );
          }

          await new Promise(resolve => setTimeout(() => resolve(0), 500));
        }
      } catch (e) {
        console.log({ e });
      }
    }

    this._signSecure.signPdfData = await pdfDoc.save();
    this._signSecure.signPdfName = this.data.name;
    this._loader.show();
    const correctName = this._signSecure.getCorrectFilename(this.data.name);

    const formatData = {
      file: this._signSecure.bytesToFile(
        this._signSecure.signPdfData,
        correctName
      ),
      signatures: this.signatures,
      parties: this.data?.workflow?.parties,
      id: this.data.id,
    };

    // this.developManifest(await pdfDoc.copy());

    const { file, ...rest } = formatData;
    this._signSecure.pendingSignature({ ...this.data, path });
    this.subscription.add(
      this._signSecure
        .documentSigned(formatData)
        .subscribe(async (data: any) => {
          if (data) {
            this.checkIfAllSignatures(await pdfDoc.copy(), data.data);
            // this.developManifest(pdfDoc)
          }
        })
    );
  }

  async generateApprovedDocument(data: any) {
    await this.getUpdatedDocument();
    let path = '../../../assets/dms-documents/pdf-test.pdf';
    if (!environment.local) {
      path = `./assets/${this.data.path}`;
    }

    const bytes = await fetch(path).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(bytes);
    console.log('[generateApprovedDocument] ', data);
    this._signSecure.pendingSignature(data);
    await this.checkIfAllSignatures(pdfDoc, data);
    this.done = true;
    this.doneData = {
      id: this.data.id,
      image: '../../../assets/images/approve-document.svg',
      title: 'Document approved',
      description: `The document <strong>“${this.data.name}”</strong> has been approved.`,
    };
  }

  async developManifest(pdfDoc: any) {
    await this.addQRCode(pdfDoc);
    await this.createManifest(pdfDoc);
    this._signSecure.signPdfData = await pdfDoc.save();
    this.download();
    this._loader.hide();
  }

  getYLocation(y: number, canvaHeight: number, pageHeight: number) {
    const diff = Math.abs(canvaHeight - pageHeight);
    const aspectRatio = canvaHeight / pageHeight;
    const mid = canvaHeight / 2;

    let newY = pageHeight - y;

    if (newY > pageHeight * 0.9) {
      newY = newY * aspectRatio + 50;
    } else if (newY <= pageHeight * 0.2) {
      newY = newY + diff + 100;
    }

    return newY;
  }

  getXLocation(x: number, canvaWidth: number, pageWidth: number) {
    const diff = Math.abs(canvaWidth - pageWidth);
    const aspectRatio = pageWidth / canvaWidth;
    const mid = canvaWidth / 2;

    let newX = x;

    if (newX > canvaWidth * 0.9) {
      newX = newX * aspectRatio;
    }

    return newX;
  }

  async checkIfAllSignatures(pdf: PDFDocument, data: any, firstLoad = false) {
    const everyoneDone = data?.workflow?.parties?.every(
      (party: any) => party?.role === 'COPY' || !!party?.executedFormatted
    );

    const updateAuditTrail = [...(data?.workflow?.auditTrail || [])]; // create a shallow copy
    let userTimezone;
    for (let index = 0; index < updateAuditTrail.length; index++) {
      const element = updateAuditTrail[index];
      if (element.details == 'Viewed') {
        userTimezone = element.timezone;
      }

      if (element.details == 'Signed') {
        element.timezone = userTimezone;
      }
    }
    // Create a new object reference so Angular change detection picks it up
    if (data?.workflow) {
      data.workflow = {
        ...data.workflow, // retain other properties of workflow
        auditTrail: updateAuditTrail, // assign the updated auditTrail
      };
    }

    if (everyoneDone) {
      this.data = data;
      await this.addQRCode(pdf);
      const documentData = await pdf.save();
      const document = this._signSecure.bytesToFile(
        documentData,
        'document.pdf'
      );
      const manifest = await this.createManifest(pdf);
      this._signSecure.signPdfData = await pdf.save();
      const formatData = {
        id: data.id,
        file: this._signSecure.bytesToFile(
          this._signSecure.signPdfData,
          this._file.changeExtension(this.data.name, 'pdf')
        ),
        document,
        manifest,
      };

      this._signSecure.doneSignature();
      this.subscription.add(
        this._signSecure.documentDone(formatData).subscribe(() => {
          this._loader.hide();
          this.done = true;
          this.doneData = {
            id: data.id,
          };
        })
      );
    } else {
      if (!firstLoad) {
        this._loader.hide();
        this.done = true;
        this.doneData = {
          id: data.id,
        };
      }
    }
  }

  doAction(data: any) {
    console.log({ data });
    this.setPanelContent(this.defaultRef);
    if (data?.action === 'approve') {
      this.doneData = {
        id: this.data.id,
        image: '../../../assets/images/approve-document.svg',
        title: 'Document approved',
        description: `The document <strong>“${this.data.name}”</strong> has been approved.`,
      };
      this.generateApprovedDocument(data.data);
    } else if (data?.action === 'reject') {
      this.done = true;
      this.doneData = {
        id: this.data.id,
        image: '../../../assets/images/reject-document.svg',
        title: 'Document rejected',
        description: `The document <strong>“${this.data.name}”</strong> has been rejected. Everyone in the party will be notified.`,
      };
    } else if (data?.action === 'audit-trail') {
      console.log({ trail: this.auditTrail });
      this.setPanelContent(this.auditTailRef);
      this.openDrawer = true;
    } else if (data?.action === 'document-details') {
      this._loader.show();
      this._user.getUserInfo(this.data.createdBy).subscribe(({ data }) => {
        this.data['author'] = data.user ?? data;
        this.setPanelContent(this.documentDetailsRef);
        this.openDrawer = true;
        this._loader.hide();
      });
    } else if (data?.action === 'document-declined') {
      this.done = true;
      this.doneData = {
        id: this.data.id,
        image: '../../../assets/images/decline-document.svg',
        title: 'You declined to sign the document',
        description: `Sender will be notified that you declined to sign this document`,
      };
    }
  }

  async addQRCode(pdf: PDFDocument) {
    const qrPosition = this.data.workflow?.qrPosition ?? 'bottom-right';
    let text = this.data.id;
    text = this._qr.encrypt(text);
    const qrCode = await this._qr.getQRCode(text);
    const fontBytes = await fetch('../../../assets/fonts/DMSans-Bold.ttf').then(
      res => res.arrayBuffer()
    );

    pdf.registerFontkit(fontkit);
    const docFont = await pdf.embedFont(fontBytes);

    const pages = pdf.getPages();
    const pagesLength = pages?.length ?? 0;
    for (let page = 0; page < pagesLength; page++) {
      const image = await pages[page].doc.embedPng(qrCode);
      const size = pages[page].getSize();
      console.log({ size });
      const pos = this._qr.getQRCodeConfig(qrPosition, size.height, size.width);
      pages[page].drawImage(image, { ...pos, width: 55, height: 55 });

      pages[page].drawText(`For verification, please visit:`, {
        font: docFont,
        lineHeight: 12,
        size: 6,
        maxWidth: 150,
        x: pos.x - 16,
        y: pos.y - 2,
        color: rgb(0.5, 0.5, 0.5),
      });

      pages[page].drawText(`https://dms.judiciary.gov.ph`, {
        font: docFont,
        lineHeight: 12,
        size: 6,
        maxWidth: 150,
        x: pos.x - 16.3,
        y: pos.y - 8,
        color: rgb(0, 0, 0),
      });

      const link = pages[page].doc.context.register(
        pages[page].doc.context.obj({
          Type: 'Annot',
          Subtype: 'Link',
          Rect: [pos.x, pos.y + 55, pos.x + 55, pos.y - 10],
          Border: [0, 0, 0],
          C: [0, 0, 1],
          A: {
            Type: 'Action',
            S: 'URI',
            URI: PDFString.of(`https://dms.judiciary.gov.ph`),
          },
        })
      );

      // Get existing annotations
      const existingAnnotations = pages[page].node.Annots();

      pages[page].node.set(
        PDFName.of('Annots'),
        pdf.context.obj([...(existingAnnotations?.asArray() || []), link])
      );
    }
  }

  async createManifest(pdf: PDFDocument) {
    const pdfDoc = await PDFDocument.create({});
    // Add SC Image
    const arrayBuffer = await fetch('../../../assets/images/icon-sc.png').then(
      res => res.arrayBuffer()
    );
    const image = await pdfDoc.embedPng(arrayBuffer);

    let text = this.data.id;
    text = this._qr.encrypt(text);
    const qrCode = await this._qr.getQRCode(text);
    const qrImage = await pdfDoc.embedPng(qrCode);

    //Get font
    const fontBytes = await fetch('../../../assets/fonts/DMSans.ttf').then(
      res => res.arrayBuffer()
    );
    const fontItalicBytes = await fetch(
      '../../../assets/fonts/DMSans-Italic.ttf'
    ).then(res => res.arrayBuffer());
    const fontBoldBytes = await fetch(
      '../../../assets/fonts/DMSans-Bold.ttf'
    ).then(res => res.arrayBuffer());

    // Register Font
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const customItalicFont = await pdfDoc.embedFont(fontItalicBytes);
    const customBoldFont = await pdfDoc.embedFont(fontBoldBytes);

    const events = [];
    const auditTrail = this.data?.workflow?.auditTrail;
    for (let trail of auditTrail) {
      const user = await lastValueFrom(
        this._user.getUserInfo(trail?.author?.id)
      );
      const userData = user?.data;

      events.push({
        event: trail?.details?.toLowerCase() ?? '',
        user: `${trail?.author?.name} (${userData?.email})`,
        time: trail?.timestamp,
        timezone: trail?.timezone,
      });
    }

    events.push({
      event: 'completed',
      user: '',
      time: moment.now(),
      timezone: momentTimezone.tz.guess(),
    });

    const pageNum = pdf.getPageCount();
    const firstPage = events.slice(0, 8);

    const page = pdfDoc.addPage(PageSizes.Folio);
    page.drawImage(image, { x: 416, y: 851.31, width: 134, height: 28.84 });

    // Create "Manifest" Text
    page.drawText('Manifest', {
      size: 16,
      x: 62,
      y: 858,
      font: customBoldFont,
      color: rgb(0.13, 0.13, 0.13),
    });

    // Create lines
    page.drawLine({
      start: { x: 62, y: 828 },
      end: { x: 550, y: 828 },
      thickness: 1,
      color: rgb(0.13, 0.13, 0.13),
      opacity: 1,
    });

    const userData = await lastValueFrom(
      this._user.getUserInfo(this.data.createdBy)
    );

    const updateAuditTrail = this.data?.workflow?.auditTrail; // create a shallow copy
    let timezone = '';
    for (let index = 0; index < updateAuditTrail.length; index++) {
      const element = updateAuditTrail[index];

      if (element.details == 'Created') {
        timezone = element.timezone;
      }
    }

    console.log({ signingData: this.data });
    this.createManifestInfo(page, customFont, {
      id: this.data.id,
      created:
        this.data?.date_created ?? this.data?.createdAtFormatted.split(',')[0],
      createdByName: `${userData?.data?.givenName} ${userData?.data?.lastName}`,
      createdByEmail: userData?.data?.email,
    });

    for (const [index, party] of firstPage?.entries()) {
      await this.addSignatureData(
        party,
        index,
        page,
        customFont,
        customBoldFont
      );
    }

    if (firstPage.length !== 8) {
      this.addDocumentDetails(
        page,
        customItalicFont,
        {
          pages: pageNum,
          space: 85 * firstPage?.length ?? 0,
          createdByName: `${userData?.data?.givenName} ${userData?.data?.lastName}`,
          createdByEmail: userData?.data?.email,
          createdByTimezone: timezone,
          ...this.data,
        },
        719 + (7.5 * firstPage?.length ?? 0)
      );
    }

    this.addFooter(
      page,
      customBoldFont,
      {
        image: qrImage,
      },
      pdfDoc
    );

    //suceeding pages
    const chunkSize = 10;
    for (let i = 8; i < events.length; i += chunkSize) {
      const chunk = events.slice(i, i + chunkSize);
      console.log({ chunk });
      const page = pdfDoc.addPage(PageSizes.Folio);
      page.drawImage(image, { x: 416, y: 851.31, width: 134, height: 28.84 });

      // Create "Manifest" Text
      page.drawText('Manifest', {
        size: 16,
        x: 62,
        y: 858,
        font: customBoldFont,
        color: rgb(0.13, 0.13, 0.13),
      });

      // Create lines
      page.drawLine({
        start: { x: 62, y: 828 },
        end: { x: 550, y: 828 },
        thickness: 1,
        color: rgb(0.13, 0.13, 0.13),
        opacity: 1,
      });

      for (const [index, party] of chunk?.entries()) {
        await this.addSignatureData(
          party,
          index,
          page,
          customFont,
          customBoldFont,
          788
        );
      }

      console.log({ i, length: events.length });
      if (i + chunkSize >= events.length && (events.length - 8) % 10 !== 0) {
        this.addDocumentDetails(
          page,
          customItalicFont,
          {
            pages: pageNum,
            space: 85 * chunk?.length ?? 0,
            createdByName: `${userData?.data?.givenName} ${userData?.data?.lastName}`,
            createdByEmail: userData?.data?.email,
            createdByTimezone: timezone,
            ...this.data,
          },
          820 + (17 * (chunk?.length - 1) ?? 0)
        );
      }

      this.addFooter(
        page,
        customBoldFont,
        {
          image: qrImage,
        },
        pdfDoc
      );
    }

    if (events.length === 8 || (events.length - 8) % 10 === 0) {
      const page = pdfDoc.addPage(PageSizes.Folio);
      page.drawImage(image, { x: 416, y: 851.31, width: 134, height: 28.84 });

      // Create "Manifest" Text
      page.drawText('Manifest', {
        size: 16,
        x: 62,
        y: 858,
        font: customBoldFont,
        color: rgb(0.13, 0.13, 0.13),
      });

      // Create lines
      page.drawLine({
        start: { x: 62, y: 828 },
        end: { x: 550, y: 828 },
        thickness: 1,
        color: rgb(0.13, 0.13, 0.13),
        opacity: 1,
      });

      this.addDocumentDetails(
        page,
        customItalicFont,
        {
          pages: pageNum,
          space: 88,
          createdByName: `${userData?.data?.givenName} ${userData?.data?.lastName}`,
          createdByEmail: userData?.data?.email,
          createdByTimezone: timezone,
          ...this.data,
        },
        868
      );

      this.addFooter(
        page,
        customBoldFont,
        {
          image: qrImage,
        },
        pdfDoc
      );
    }

    const copyPages = await pdf.copyPages(
      pdfDoc,
      Array.from(Array(pdfDoc.getPageCount()).keys())
    );

    for (let copyPage of copyPages) {
      console.log({ copyPage });
      pdf.addPage(copyPage);
    }

    const manifestData = await pdfDoc.save();
    return this._signSecure.bytesToFile(manifestData, 'manifest.pdf');
  }

  download(data: any = this._signSecure.signPdfData, type = 'application/pdf') {
    var blob = new Blob([data], { type });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = this._signSecure.signPdfName;
    link.download = fileName;
    link.click();
  }

  getRoleEvent(role: string) {
    switch (role) {
      case 'SIGN':
        return 'signed';
      case 'APPROVER':
        return 'approved';
    }

    return '';
  }

  async addSignatureData(
    event: any,
    index: number,
    page: PDFPage,
    font: PDFFont,
    boldFont: PDFFont,
    y: number = 642
  ) {
    const arrayBuffer = await fetch(
      `../../../assets/images/manifest/${event.event}.png`
    ).then(res => res.arrayBuffer());
    const image = await page.doc.embedPng(arrayBuffer);

    const marginLeft = 35;
    const space = 62 * index;
    page.drawImage(image, { x: 62, y: y - 5 - space, width: 16, height: 16 });

    page.drawText(
      `Document ${event.event} ${event.user ? `by ${event.user}` : ''}`,
      {
        x: 62 + marginLeft,
        y: y - space,
        size: 12,
        lineHeight: 16,
        font: font,
        color: rgb(0.28, 0.28, 0.28),
      }
    );

    const eventTime = event?.time ? moment(event?.time) : moment();
    const eventTimezone = event?.timezone || moment.tz.guess();
    const formattedTime = eventTime
      .tz(eventTimezone)
      .format('MM/DD/YYYY - HH:mm:ss a ([GMT] Z)');

    page.drawText(
      `${formattedTime} ${event?.timezone ? `- ${event?.timezone}` : ''}`,
      {
        x: 62 + marginLeft,
        y: y - 20 - space,
        size: 12,
        lineHeight: 14,
        color: rgb(0.28, 0.28, 0.28),
        font: font,
      }
    );
  }

  async addDocumentDetails(
    page: PDFPage,
    font: PDFFont,
    details: any,
    y: number = 575
  ) {
    console.log({ pageDetails: details });
    const completedTimezone = momentTimezone.tz.guess();
    page.drawText(
      `Document created on ${
        moment(details.createdAt)
          .tz(details.createdByTimezone)
          .format('DD MMM YYYY, HH:mm a ([GMT] Z)') ?? '03/22/2023 - 10:52 am'
      } - ${details.createdByTimezone} by ${details.createdByName} | ${
        details.createdByEmail
      }` +
        `\nTotal no. of pages (excl. Manifest): ${details.pages} page/s\n\n` +
        `Signatures completed by all parties on ${moment()
          .tz(completedTimezone)
          .format('MM/DD/YYYY - HH:mm a ([GMT] Z)')} - ${completedTimezone}`,
      {
        size: 7.5,
        x: 62,
        y: y - details?.space ?? 0,
        font: font,
        lineHeight: 10,
        color: rgb(0.64, 0.64, 0.64),
      }
    );
  }

  addFooter(page: PDFPage, font: PDFFont, details: any, pdfDoc: PDFDocument) {
    page.drawImage(details?.image, {
      width: 55,
      height: 55,
      x: 503,
      y: 31,
    });

    page.drawText(`For verification, please visit:`, {
      font: font,
      x: 507 - 16,
      y: 30,
      color: rgb(0.5, 0.5, 0.5),
      lineHeight: 12,
      size: 6,
    });

    page.drawText(`https://dms.judiciary.gov.ph`, {
      font: font,
      lineHeight: 12,
      size: 6,
      maxWidth: 150,
      x: 507 - 16.4,
      y: 23,
      color: rgb(0.0, 0.0, 0.0),
    });

    const link = page.doc.context.register(
      page.doc.context.obj({
        Type: 'Annot',
        Subtype: 'Link',
        Rect: [503, 95, 558, 25],
        Border: [0, 0, 0],
        C: [0, 0, 1],
        A: {
          Type: 'Action',
          S: 'URI',
          URI: PDFString.of(`https://dms.judiciary.gov.ph`),
        },
      })
    );

    page.node.set(PDFName.of('Annots'), pdfDoc.context.obj([link]));

    page.drawText(
      'This document was signed with SignSecure™ Scan the QR code to verify document',
      {
        font: font,
        lineHeight: 12,
        size: 9,
        maxWidth: 200,
        x: 62,
        y: 50,
        color: rgb(0.4, 0.4, 0.4),
      }
    );
  }

  closeSlider() {
    this.openDrawer = false;
  }

  createManifestInfo(page: PDFPage, font: PDFFont, details: any) {
    const rectLocation = { x: 62, y: 788 };
    // create rectangle
    page.drawRectangle({
      ...rectLocation,
      y: rectLocation.y - 102.5,
      width: 488,
      height: 116,
      color: rgb(0.5, 0.36, 0.6),
      opacity: 0.2,
      borderOpacity: 0,
    });

    const marginTop = 18;
    const marginLeft = 18;
    // create starting text lines
    page.drawText('Created:\n' + 'By:\n' + 'Status:\n' + 'Transaction ID:\n', {
      size: 10,
      lineHeight: 20,
      font,
      color: rgb(0.28, 0.28, 0.28),
      x: rectLocation.x + marginLeft,
      y: rectLocation.y - marginTop, // the page starts 0 at the bottom
    });

    const contentMarginLeft = 144;
    page.drawText(
      `${details?.created ?? '2023-04-01'}\n` +
        `${details?.createdByName ?? 'Juan Dela Cruz'} (${
          details?.createdByEmail ?? 'jdelacruz@email.com'
        })\n` +
        `Completed\n` +
        `${details?.id ?? 'OGOTKBKDCRMSCKFVMSL_564243'}\n`,
      {
        size: 10,
        lineHeight: 20,
        font,
        color: rgb(0.28, 0.28, 0.28),
        x: rectLocation.x + contentMarginLeft,
        y: rectLocation.y - marginTop, // the page starts 0 at the bottom
      }
    );
  }

  setPanelContent(
    componentOrTemplateRef: ComponentType<any> | TemplateRef<any>
  ) {
    let portal: Portal<any>;
    if (componentOrTemplateRef instanceof TemplateRef) {
      portal = new TemplatePortal(componentOrTemplateRef, this.vcf);
    } else {
      portal = new ComponentPortal(componentOrTemplateRef);
    }
    this.panelPortal$.next(portal);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (
      this._loader.isLoading &&
      event.key === 'p' &&
      (event.ctrlKey || event.metaKey)
    ) {
      event.preventDefault();
    }
  }
}

<mat-sidenav-container *ngIf="isPhoneWidth">
  <mat-sidenav #ref2 mode="side" disableClose class="sidebar-phone"
    ><div class="sidebar-phone">
      <div class="account-header">
        <img
          src="../../../assets/images/avatar.png"
          alt=""
          class="small-logo" />
        <div class="account-info">
          <a class="name">{{ name }}</a>
          <a class="email">{{ email }}</a>
          <a class="logout" (click)="logout()">Logout</a>
        </div>
      </div>
      <img
        src="../../../assets/images/sidebar-logo.png"
        alt=""
        class="logo"
        (click)="updateLocation('report')" />
      <div class="sidebar-content">
        <div class="sidebar-items">
          <div
            [ngClass]="
              selected == 'report' ? 'sidebar-item active' : 'sidebar-item'
            "
            (click)="updateLocation('report')">
            <div class="icon icon-dashboard"></div>
            <p>Dashboard</p>
          </div>
          <div
            [ngClass]="
              selected == 'inbox' ? 'sidebar-item active' : 'sidebar-item'
            "
            (click)="updateLocation('inbox')">
            <div class="icon icon-inbox"></div>
            <p>Inbox</p>
          </div>
          <div
            [ngClass]="
              selected == 'docs' ? 'sidebar-item active' : 'sidebar-item'
            "
            (click)="updateLocation('docs')">
            <div class="icon icon-documents"></div>
            <p>Documents</p>
          </div>
          <div
            [ngClass]="
              selected == 'recent-docs' ? 'sidebar-item active' : 'sidebar-item'
            "
            (click)="updateLocation('recent-docs')">
            <div class="icon icon-recents"></div>
            <p>Recent Documents</p>
          </div>
          <div
            [ngClass]="
              selected == 'listMenu' ? 'sidebar-item active' : 'sidebar-item'
            "
            (click)="updateLocation('listMenu')">
            <div class="icon icon-folder"></div>
            <p>Folders and Files</p>
          </div>
          <div
            *ngIf="!isMobile"
            [ngClass]="
              selected == 'templates' ||
              selected == 'safe-form' ||
              selected == 'certificates-table'
                ? 'sidebar-item active'
                : 'sidebar-item'
            "
            (click)="updateLocation('templates')">
            <div class="icon icon-form-templates"></div>
            <p>Form Templates</p>
          </div>
          <div
            [ngClass]="
              selected == 'workflow' ? 'sidebar-item active' : 'sidebar-item'
            "
            (click)="updateLocation('workflow')">
            <div class="icon icon-workflow"></div>
            <p>Workflow</p>
          </div>
          <div
            [ngClass]="
              selected == 'signatures' ? 'sidebar-item active' : 'sidebar-item'
            "
            (click)="updateLocation('signatures')"
            *ngIf="signaturesMenuFlag">
            <div class="icon icon-signature"></div>
            <p>Signatures</p>
          </div>
          <div
            [ngClass]="
              selected == 'faq' ? 'sidebar-item active' : 'sidebar-item'
            "
            (click)="updateLocation('faq')">
            <div class="icon icon-faq"></div>
            <p>FAQ</p>
          </div>
          <div
            [ngClass]="
              selected == 'support' ? 'sidebar-item active' : 'sidebar-item'
            "
            (click)="updateLocation('support')">
            <div class="icon icon-support"></div>
            <p>Support</p>
          </div>
          <app-admin-menu
            (toggleSideNavEvent)="closeSideNav()"
            *ngIf="userType === 'admin' && !isPhoneWidth"></app-admin-menu>
        </div>
        <div class="sidebar-footer">
          <div *ngIf="unawaLogoFlag" class="footer-logo">
            <span>Powered by:</span>
            <img src="../../../assets/images/unawa-logo-text.png" alt="" />
          </div>
          <p class="footer-text">
            <a
              class="emphasize"
              href="https://sign-sc-live.web.app/terms-and-conditions"
              target="_blank"
              >Terms & Conditions</a
            >
            |
            <a
              class="emphasize"
              href="https://sign-sc-live.web.app/privacy-policy"
              target="_blank"
              >Privacy Policy</a
            ><br />
            © 2025
            <a class="emphasize" href="https://www.unawa.asia/" target="_blank"
              >UNAWA</a
            >
            . All rights reserved<br />
            <span class="version">Version: {{ version }}</span>
          </p>
        </div>
      </div>
    </div></mat-sidenav
  >
</mat-sidenav-container>
<ng-container *ngIf="!isPhoneWidth">
  <div class="sidebar">
    <div class="account-header">
      <img src="../../../assets/images/avatar.png" alt="" class="small-logo" />
      <div class="account-info">
        <a class="name">{{ name }}</a>
        <a class="email">{{ email }}</a>
        <a class="logout" (click)="logout()">Logout</a>
      </div>
    </div>
    <img
      src="../../../assets/images/sidebar-logo.png"
      alt=""
      class="logo"
      (click)="updateLocation('report')" />
    <div class="sidebar-content">
      <div class="sidebar-items">
        <div
          [ngClass]="
            selected == 'report' ? 'sidebar-item active' : 'sidebar-item'
          "
          (click)="updateLocation('report')">
          <div class="icon icon-dashboard"></div>
          <p>Dashboard</p>
        </div>
        <div
          [ngClass]="
            selected == 'inbox' ? 'sidebar-item active' : 'sidebar-item'
          "
          (click)="updateLocation('inbox')">
          <div class="icon icon-inbox"></div>
          <p>Inbox</p>
        </div>
        <div
          [ngClass]="
            selected == 'docs' ? 'sidebar-item active' : 'sidebar-item'
          "
          (click)="updateLocation('docs')">
          <div class="icon icon-documents"></div>
          <p>Documents</p>
        </div>
        <div
          [ngClass]="
            selected == 'recent-docs' ? 'sidebar-item active' : 'sidebar-item'
          "
          (click)="updateLocation('recent-docs')">
          <div class="icon icon-recents"></div>
          <p>Recent Documents</p>
        </div>
        <div
          [ngClass]="
            selected == 'listMenu' ? 'sidebar-item active' : 'sidebar-item'
          "
          (click)="updateLocation('listMenu')">
          <div class="icon icon-folder"></div>
          <p>Folders and Files</p>
        </div>
        <div
          *ngIf="!isMobile"
          [ngClass]="
            selected == 'certificates-table'
              ? 'sidebar-item active'
              : 'sidebar-item'
          "
          (click)="updateLocation('templates')">
          <div class="icon icon-form-templates"></div>
          <p>Form Templates</p>
        </div>
        <div
          [ngClass]="
            selected == 'workflow' ? 'sidebar-item active' : 'sidebar-item'
          "
          (click)="updateLocation('workflow')">
          <div class="icon icon-workflow"></div>
          <p>Workflow</p>
        </div>
        <div
          [ngClass]="
            selected == 'signatures' ? 'sidebar-item active' : 'sidebar-item'
          "
          (click)="updateLocation('signatures')"
          *ngIf="signaturesMenuFlag">
          <div class="icon icon-signature"></div>
          <p>Signatures</p>
        </div>
        <div
          [ngClass]="selected == 'faq' ? 'sidebar-item active' : 'sidebar-item'"
          (click)="updateLocation('faq')">
          <div class="icon icon-faq"></div>
          <p>FAQ</p>
        </div>
        <div
          [ngClass]="
            selected == 'support' ? 'sidebar-item active' : 'sidebar-item'
          "
          (click)="updateLocation('support')">
          <div class="icon icon-support"></div>
          <p>Support</p>
        </div>
        <app-admin-menu *ngIf="userType === 'admin'"></app-admin-menu>
      </div>
      <div class="sidebar-footer">
        <div *ngIf="unawaLogoFlag" class="footer-logo">
          <span>Powered by:</span>
          <img src="../../../assets/images/unawa-logo-text.png" alt="" />
        </div>
        <p class="footer-text">
          <a
            class="emphasize"
            href="https://sign-sc-live.web.app/terms-and-conditions"
            target="_blank"
            >Terms & Conditions</a
          >
          |
          <a
            class="emphasize"
            href="https://sign-sc-live.web.app/privacy-policy"
            target="_blank"
            >Privacy Policy</a
          ><br />
          © 2025
          <a class="emphasize" href="https://www.unawa.asia/" target="_blank"
            >UNAWA</a
          >
          . All rights reserved<br />
          <span class="version">Version: {{ version }}</span>
        </p>
      </div>
    </div>
  </div></ng-container
>

<app-spinner></app-spinner>
<div class="pdf-viewer-container">
  <div class="pdf-viewer-nav" *ngIf="!isPhoneWidth">
    <div class="left">
      <button (click)="goBack()">
        <img
          src="../../../assets/images/icons/back.svg"
          alt=""
          class="back-button" />
      </button>

      <button class="cta-buttons" (click)="download()">
        <img src="../../../assets/images/icons/download-white.svg" alt="" />
        <p>Download</p>
      </button>
      <button class="cta-buttons" *ngIf="showSignatureButton">
        <img src="../../../assets/images/sidebar/signature-white.svg" alt="" />
        <p>Signature</p>
      </button>
      <button
        [disabled]="isPublished"
        [ngClass]="isPublished ? 'cta-buttons disabled' : 'cta-buttons'"
        (click)="useSignSecure()"
        *ngIf="showSignSecureButton">
        <p>Use SignSecure</p>
      </button>
    </div>
    <div class="center">
      <button (click)="print()">
        <img src="../../../assets//images/template-icons/printer.svg" alt="" />
      </button>
      <button (click)="zoomLevel('in')">
        <img src="../../../assets//images/icons/zoom-in.svg" alt="" />
      </button>
      <button (click)="zoomLevel('out')">
        <img src="../../../assets//images/icons/zoom-out.svg" alt="" />
      </button>
      <select name="" id="" [(ngModel)]="zoom">
        <option value="0.25">25%</option>
        <option value="0.5">50%</option>
        <option value="0.75">75%</option>
        <option value="1">100%</option>
        <option value="1.25">125%</option>
        <option value="1.5">150%</option>
        <option value="1.75">175%</option>
        <option value="2">200%</option>
        <option value="2.25">225%</option>
        <option value="2.5">250%</option>
        <option value="2.75">275%</option>
        <option value="3">300%</option>
      </select>
      <div class="divider"></div>
      <button (click)="pageNavi('up')">
        <img
          src="../../../assets//images/icons/up.svg"
          alt=""
          class="back-button" />
      </button>
      <div class="page-text">{{ pageNumber }} of {{ totalPages }}</div>
      <button (click)="pageNavi('down')">
        <img
          src="../../../assets//images/icons/down.svg"
          alt=""
          class="back-button" />
      </button>
    </div>
    <div class="right">
      <button
        [disabled]="isPublished && !isSignSecure"
        [ngClass]="isPublished ? 'cta-buttons disabled' : 'cta-buttons'"
        (click)="openModal()"
        *ngIf="showShareButton">
        <img src="../../../assets/images/icons/share-white.svg" alt="" />
        <p>Share</p>
      </button>
      <button
        [disabled]="disableQRButton || isPublished || isSignSecure"
        [ngClass]="
          disableQRButton || isPublished || isSignSecure
            ? 'cta-buttons text-shadow disabled'
            : 'cta-buttons text-shadow'
        "
        *ngIf="showQRButton"
        (click)="showQRScreen()">
        <img src="../../../assets/images/qr-button-icon.svg" alt="" />
        <p>Add QR Code</p>
      </button>
      <button
        [disabled]="(isPublished && !isSignSecure) || disableWatermarkButton"
        [ngClass]="
          disableWatermarkButton || (isPublished && !isSignSecure)
            ? 'cta-buttons text-shadow disabled'
            : 'cta-buttons text-shadow '
        "
        *ngIf="showQRButton"
        (click)="showWatermarkScreen()">
        <img src="../../../assets/images/icons/watermark.svg" alt="" />
        <p>Add Watermark</p>
      </button>
    </div>
  </div>
  <div class="phone-nav" *ngIf="isPhoneWidth">
    <div class="left">
      <button (click)="goBack()">
        <img src="../../../assets/images/iphone-assets/back.svg" alt="" />
      </button>
    </div>
    <div class="right">
      <button (click)="openModal()" *ngIf="showShareButton">
        <img src="../../../assets/images/iphone-assets/share.svg" alt="" />
      </button>
      <button (click)="download()">
        <img src="../../../assets/images/iphone-assets/download.svg" alt="" />
      </button>
      <button (click)="useSignSecure()" *ngIf="showSignSecureButton">
        <img src="../../../assets/images/iphone-assets/sign.svg" alt="" />
      </button>
    </div>
  </div>
  <div class="content" #content>
    <div
      class="overlay"
      [style.display]="showQRModal"
      [style.height]="overlayHeight"
      (click)="showQRScreen()"
      #overlay></div>
    <div
      class="overlay"
      [style.display]="showWatermarkModal"
      [style.height]="overlayHeight"
      (click)="showWatermarkScreen()"
      #overlay></div>
  </div>
  <pdf-viewer
    *ngIf="!isPhoneWidth"
    [src]="pdfSrc"
    [render-text]="true"
    [original-size]="true"
    [fit-to-page]="true"
    [zoom]="zoom"
    [(page)]="pageNumber"
    (after-load-complete)="afterLoadComplete($event)"
    class="pdf"
    [external-link-target]="'blank'"
    #viewer></pdf-viewer>
  <pdf-viewer
    *ngIf="isPhoneWidth"
    [src]="pdfSrc"
    [fit-to-page]="true"
    [zoom]="zoom"
    [(page)]="pageNumber"
    [show-borders]="true"
    (after-load-complete)="afterLoadComplete($event)"
    [external-link-target]="'blank'"
    [render-text]="true"
    class="pdf"></pdf-viewer>
  <div class="page-nav" *ngIf="isPhoneWidth">
    <button (click)="pageNavi('up')">
      <img src="../../../assets/images/iphone-assets/page-up.svg" />
    </button>
    <div class="page-number">{{ pageNumber }} of {{ totalPages }}</div>
    <button (click)="pageNavi('down')">
      <img src="../../../assets/images/iphone-assets/page-down.svg" />
    </button>
  </div>

  <div class="qr-menu" [style.display]="showQRModal">
    <img class="qr-menu__image" src="../../../assets/images/qr.svg" alt="" />

    <h4>Set QR Code Position</h4>
    <div class="select-field">
      <label for="qr-postion">QR Code Position</label>
      <mat-select
        name="qr-position"
        (selectionChange)="qrPositionChanged($event)"
        [(ngModel)]="qrPosition">
        <mat-option value="top-right" selected>Top Right</mat-option>
        <mat-option value="top-left">Top Left</mat-option>
        <mat-option value="top-center">Top Center</mat-option>
        <mat-option value="bottom-right">Bottom Right</mat-option>
        <mat-option value="bottom-left">Bottom Left</mat-option>
        <mat-option value="bottom-center">Bottom Center</mat-option>
      </mat-select>
    </div>
    <div class="privacy-options-container">
      <label for="privacy-options"
        >Document privacy
        <img
          src="../../../../../assets//images/template-icons/question-mark.svg"
          alt=""
          matTooltip="Confidential - can be viewed by parties involved only
      Restricted - can be viewed by all signed in users
      Public - can be viewed by the general public"
      /></label>

      <mat-radio-group
        aria-labelledby="privacy-options"
        class="privacy-options"
        [(ngModel)]="privacy"
        id="privacy-options">
        <mat-radio-button
          class="privacy-option"
          [value]="'CONFIDENTIAL'"
          selected>
          Confidential
        </mat-radio-button>
        <mat-radio-button class="privacy-option" [value]="'RESTRICTED'">
          Restricted
        </mat-radio-button>
        <mat-radio-button class="privacy-option" [value]="'PUBLIC'">
          Public
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <span
      >Note: QR Codes will be added to every document pages and its position
      can't be change once saved</span
    >
    <div class="cta-buttons">
      <button class="cancel" (click)="showQRScreen()">Cancel</button>
      <button class="save" (click)="save()">Save</button>
    </div>
  </div>

  <div class="watermark-menu" [style.display]="showWatermarkModal">
    <h4>Add watermark to the document</h4>
    <div class="content">
      <div class="input-text">
        <label for="watermark-text"
          >Watermark text (maximum of 30 characters)</label
        >
        <input
          type="text"
          placeholder="Watermark text"
          label="Watermark Text"
          name="watermark-text"
          (ngModelChange)="watermarkTextChanged($event)"
          [(ngModel)]="watermarkText"
          maxlength="30" />
      </div>
      <div class="select-field">
        <label for="watermark-postiion">Location</label>
        <mat-select
          name="watermark-postiion"
          (selectionChange)="watermarkPositionChanged($event)"
          [(ngModel)]="watermarkPosition">
          <mat-option value="center" selected>Center</mat-option>
          <mat-option value="top">Top</mat-option>
          <mat-option value="bottom">Bottom</mat-option>
        </mat-select>
      </div>
      <div class="style-container">
        <div class="select-field">
          <label for="watermark-color">Color</label>
          <app-color-picker
            [color]="watermarkColor"
            (changes)="watermarkColorChanged($event)"></app-color-picker>
        </div>
        <div class="select-field">
          <label for="watermark-opacity">Opacity</label>
          <mat-select
            name="watermark-opacity"
            (selectionChange)="watermarkOpacityChanged($event)"
            [(ngModel)]="watermarkOpacity">
            <mat-option value="0.15">15%</mat-option>
            <mat-option value="0.25" selected>25%</mat-option>
            <mat-option value="0.35">35%</mat-option>
            <mat-option value="0.5">50%</mat-option>
          </mat-select>
        </div>
        <div class="select-field">
          <label for="watermark-text-direction">Text Direction</label>
          <mat-select
            name="watermark-text-direction"
            (selectionChange)="watermarkTextDirectionChanged($event)"
            [(ngModel)]="watermarkTextDirection">
            <mat-option value="diagonal" selected>Diagonal</mat-option>
            <mat-option value="horizontal">Horizontal</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="checkbox-field">
        <label>Include contents</label>
        <div class="checkboxes">
          <mat-checkbox
            [checked]="watermarkShowMarked"
            (change)="watermarkShowMarkedChanged($event)"
            disableRipple
            >Watermarked by</mat-checkbox
          >
          <mat-checkbox
            [checked]="watermarkShowShared"
            (change)="watermarkShowSharedChanged($event)"
            disableRipple
            >Shared with</mat-checkbox
          >
        </div>
      </div>
      <span
        >Note: Watermark will be added to every document pages and its location
        can’t be change once saved.</span
      >

      <div class="cta-buttons">
        <button class="cancel" (click)="showWatermarkScreen()">Cancel</button>
        <button class="save" (click)="saveWatermark()">Save</button>
      </div>
    </div>
  </div>
</div>

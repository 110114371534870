import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { SignSecureService } from 'src/app/@shared/services/signsecure.service';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import * as momentTimezone from 'moment-timezone'
@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.sass']
})
export class ReviewComponent implements OnInit {

  data: any

  subject: string = ''
  message: string = ''

  dataSent: boolean = false
  privacy: string = 'CONFIDENTIAL'


  showReminders: boolean = false
  constructor(private _signSecure: SignSecureService, private _loader: LoaderService, private _snack: MatSnackBar) { }

  ngOnInit(): void {
    this._signSecure.worflowData$.subscribe(data => {
      console.log({ data })
      this.data = data
      this.data.timezone = momentTimezone.tz.guess();
      this.subject = `Please sign ${data.name}`
    })
  }

  goBack(stage: number) {
    this._signSecure.nextProgress(stage)
  }

  done() {
    if (this.dataSent) return

    console.log({ data: this.data })


    if (this.showReminders) {
      // expiration
      const reminders = this.data.workflow.reminders
      if (!!!reminders) {
        this._snack.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { type: 'error', text: 'Please select expiry date' },
        });
        return
      }

      //reminderBeforeXDays
      if (!!+reminders.reminderBeforeXDays) {
        const now = moment().startOf('day')
        now.add(+reminders.reminderBeforeXDays, 'days')

        if (now.isAfter(reminders.expiration)) {
          this._snack.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: { type: 'error', text: `Please enter a date prior to the expiration date` },
          });
          return
        }
      }

      //reminderEveryXDays
      if (!!reminders.reminderEveryXDays) {
        const now = moment().startOf('day')
        now.add(+reminders.reminderEveryXDays, 'days')

        if (now.isAfter(reminders.expiration)) {
          this._snack.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: { type: 'error', text: `Please enter a date prior to the expiration date` },
          });
          return
        }
      }

      //reminderInXDays
      if (!!reminders.reminderInXDays) {
        const now = moment().startOf('day')
        now.add(+reminders.reminderInXDays, 'days')

        if (now.isAfter(reminders.expiration)) {
          this._snack.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: { type: 'error', text: `Please enter a date prior to the expiration date` },
          });
          return
        }
      }
    }


    this.dataSent = true
    this._loader.show()

    this._signSecure.createWorkflow({ ...this.data, workflow: { ...this.data.workflow, emailContent: { subject: this.subject, message: this.message }, privacyType: this.privacy } }).subscribe(result => {
      console.log({ result })
      this._signSecure.nextProgress(6)
      this._loader.hide()
    })
  }

  clearReminders(event: any) {
    this.showReminders = event.checked
    if (!event.checked) {
      this._signSecure.updateReminders(undefined)
    }
  }
}
